import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { CanonicalDomainService } from './shared/canonical-domain/canonical-domain.service';
import { ErrorHandler } from './shared/error-handler';
import { Globals } from './globals';
import { RetsResource, RetsSource } from './shared/rets-system/rets-source';
import { RetsSystemService } from './shared/rets-system/rets-system.service';
import { ServerConfiguration } from './server-configuration/server-configuration';
import { ServerConfigurationService } from './server-configuration/server-configuration.service';
import { ServiceResult } from './shared/service-result';

@Injectable()
export class GlobalsService {
    private auditSubscriptionStatusType: string = 'AuditSubscriptionStatus';
    private auditSubscriptionTypeType: string = 'AuditSubscriptionType';
    private augmentationScopeType: string = 'AugmentationScope';
    private updateDestinationType: string = 'UpdateDestination';
    private updateStrategyType: string = 'UpdateStrategy';
    private transformStatusType: string = 'TransformStatus';

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public errorHandler: ErrorHandler,
        private canonicalDomainSvc: CanonicalDomainService,
        private globals: Globals,
        private retsSysSvc: RetsSystemService,
        private serverConfigSvc: ServerConfigurationService
    ) { }

    getAuditSubscriptionStatusText(key: number): string {
        return this.globals.auditSubscriptionStatuses[key] ? this.globals.auditSubscriptionStatuses[key] : key.toString();
    }

    getAuditSubscriptionStatusKey(value: string): number {
        return +Object.keys(this.globals.auditSubscriptionStatuses).find(key => this.globals.auditSubscriptionStatuses[key] === value);
    }

    getAuditSubscriptionTypeText(key: number): string {
        return this.globals.auditSubscriptionTypes[key] ? this.globals.auditSubscriptionTypes[key] : key.toString();
    }

    getAuditSubscriptionTypeKey(value: string): number {
        return +Object.keys(this.globals.auditSubscriptionTypes).find(key => this.globals.auditSubscriptionTypes[key] === value);
    }

    getAugmentationScopeText(key: number): string {
        return this.globals.augmentationScopes[key] ? this.globals.augmentationScopes[key] : key.toString();
    }

    getAugmentationScopeKey(value: string): number {
        return +Object.keys(this.globals.augmentationScopes).find(key => this.globals.augmentationScopes[key] == value);
    }

    getTransformStatusText(key: number): string {
        return this.globals.transformStatuses[key] ? this.globals.transformStatuses[key] : key.toString();
    }

    getTransformStatusKey(value: string): number {
        return +Object.keys(this.globals.transformStatuses).find(key => this.globals.transformStatuses[key] == value);
    }

    getUpdateDestinationText(key: number): string {
        return this.globals.updateDestinations[key] ? this.globals.updateDestinations[key] : key.toString();
    }

    getUpdateDestinationKey(value: string): number {
        return +Object.keys(this.globals.updateDestinations).find(key => this.globals.updateDestinations[key] == value);
    }

    getUpdateStrategyText(key: number): string {
        return this.globals.updateStrategies[key] ? this.globals.updateStrategies[key] : key.toString();
    }

    getUpdateStrategyKey(value: string): number {
        return +Object.keys(this.globals.updateStrategies).find(key => this.globals.updateStrategies[key] == value);
    }

    async getResources(sourceId: string) {
        if (sourceId) {
            // Get sources if they have not been populated
            if (this.globals.sources == null || this.globals.sources.length == 0) {
                await this.getSources();
            }

            let retsSource = this.globals.sources.find(s => s.id == sourceId);

            if (retsSource == null) {
                this.errorHandler.handleError('Cannot get resource list. Invalid source.');

                return [];
            }
            else if (retsSource.resources == null || retsSource.resources.length == 0) {
                await this.retsSysSvc.getResourcesSynchronous(sourceId)
                    .then((result: ServiceResult<RetsResource>) => {
                        if (result.isSuccessful) {
                            retsSource.resources = result.results;
                        }
                        else {
                            retsSource.resources = [];
                        }
                    });
            }

            return retsSource.resources;
        }
        else {
            this.errorHandler.handleError('Cannot get resource list. No source provided.');

            return [];
        }
    }

    async getSources(): Promise<RetsSource[]> {
        if (this.globals.sources == null || this.globals.sources.length == 0) {
            await this.serverConfigSvc.getServerConfigurationsSynchronous()
                .then((result: ServiceResult<ServerConfiguration>) => {
                    if (result.isSuccessful) {
                        this.globals.sources = result.results.map(s => ({
                            id: s.id,
                            name: s.name,
                            resources: []
                        }));
                    }
                    else {
                        this.globals.sources = [];
                    }
                }, error => {
                    this.errorHandler.handleError(error, true);

                    this.globals.sources = [];
                })
        }

        return this.globals.sources;
    }

    async populateEnums(): Promise<void> {
        await this.populateAuditSubscriptionStatuses();
        await this.populateAuditSubscriptionTypes();
        await this.populateAugmentationScopes();
        await this.populateTransformStatuses();
        await this.populateUpdateDestinations();
        await this.populateUpdateStrategies();
    }

    async populateAuditSubscriptionStatuses(): Promise<void> {
        if (Object.keys(this.globals.auditSubscriptionStatuses).length === 0) {
            await this.canonicalDomainSvc.getEnumList(this.auditSubscriptionStatusType)
                .then((result: ServiceResult<Record<number, string>>) => {
                    this.globals.auditSubscriptionStatuses = result.results[0];
                });
        }
    }

    async populateAuditSubscriptionTypes(): Promise<void> {
        if (Object.keys(this.globals.auditSubscriptionTypes).length === 0) {
            await this.canonicalDomainSvc.getEnumList(this.auditSubscriptionTypeType)
                .then((result: ServiceResult<Record<number, string>>) => {
                    this.globals.auditSubscriptionTypes = result.results[0];
                });
        }
    }

    async populateAugmentationScopes(): Promise<void> {
        if (Object.keys(this.globals.augmentationScopes).length === 0) {
            await this.canonicalDomainSvc.getEnumList(this.augmentationScopeType)
                .then((result: ServiceResult<Record<number, string>>) => {
                    this.globals.augmentationScopes = result.results[0];
                });
        }
    }

    async populateTransformStatuses(): Promise<void> {
        if (Object.keys(this.globals.transformStatuses).length === 0) {
            await this.canonicalDomainSvc.getEnumList(this.transformStatusType)
                .then((result: ServiceResult<Record<number, string>>) => {
                    this.globals.transformStatuses = result.results[0];
                });
        }
    }

    async populateUpdateDestinations(): Promise<void> {
        if (Object.keys(this.globals.updateDestinations).length === 0) {
            await this.canonicalDomainSvc.getEnumList(this.updateDestinationType)
                .then((result: ServiceResult<Record<number, string>>) => {
                    this.globals.updateDestinations = result.results[0];
                });
        }
    }

    async populateUpdateStrategies(): Promise<void> {
        if (Object.keys(this.globals.updateStrategies).length === 0) {
            await this.canonicalDomainSvc.getEnumList(this.updateStrategyType)
                .then((result: ServiceResult<Record<number, string>>) => {
                    this.globals.updateStrategies = result.results[0];
                });
        }
    }
}
