import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ServiceResult } from '@app/shared/service-result';
import { SmartRetsEnvironment } from '@app/shared/smart-rets-environment/smart-rets-environment';

@Injectable()
export class SmartRetsEnvironmentService {
    private readonly envUrl = 'api/environments/';

    constructor(private http: HttpClient) { }

    getCurrentEnvironment(): Observable<ServiceResult<SmartRetsEnvironment>> {
        return this.http.get<ServiceResult<SmartRetsEnvironment>>(this.envUrl + 'current');
    }
}
