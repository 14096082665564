import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../globals';
import { ServerConfiguration } from '@app/server-configuration/server-configuration';
import { ServiceResult } from '@app/shared/service-result';
import { Subscription } from '@app/subscription/subscription';

@Injectable()
export class ServerConfigurationService {
    private readonly svrConfigUrl = 'api/serverconfigurations/';
    private readonly canonicalDomainUrl = 'api/canonicaldomain/';

    constructor(
        private globals: Globals,
        private http: HttpClient
    ) { }

    addServerConfiguration(serverConfig: ServerConfiguration): Observable<ServiceResult<ServerConfiguration>> {
        // Clear global Server Configurations list
        this.globals.resetSources();

        return this.http.post<ServiceResult<ServerConfiguration>>(this.svrConfigUrl, serverConfig);
    }

    getServerConfiguration(id: string): Observable<ServiceResult<ServerConfiguration>> {
        if(id) {
            return this.http.get<ServiceResult<ServerConfiguration>>(this.svrConfigUrl + id);
        }
        else {
            let emptyResult: ServiceResult<ServerConfiguration> = {
                isSuccessful: false,
                results: [],
                exceptions: [],
                message: '',
                resultType: 'ServerConfiguration',
            }

            return observableOf(emptyResult);
        }
    }

    getServerConfigurations(): Observable<ServiceResult<ServerConfiguration>> {
        return this.http.get<ServiceResult<ServerConfiguration>>(this.svrConfigUrl);
    }

    async getServerConfigurationsSynchronous(): Promise<ServiceResult<ServerConfiguration>> {
        return await this.http.get<ServiceResult<ServerConfiguration>>(this.svrConfigUrl).toPromise();
    }

    refreshMetaData(id: string): Observable<ServiceResult<ServerConfiguration>> {
        return this.http.post<ServiceResult<ServerConfiguration>>(this.svrConfigUrl + id + '/refreshmetadata', null);
    }

    scaffoldSubscriptions(id: string): Observable<ServiceResult<Subscription>> {
        return this.http.post<ServiceResult<Subscription>>(this.svrConfigUrl + id + '/scaffoldsubscriptions', null);
    }

    updateServerConfiguration(serverConfig: ServerConfiguration): Observable<ServiceResult<ServerConfiguration>> {
        // Clear global Server Configurations list
        this.globals.resetSources();

        return this.http.put<ServiceResult<ServerConfiguration>>(this.svrConfigUrl, serverConfig);
    }

    validateServerConfiguration(serverConfig: ServerConfiguration): Observable<ServiceResult<boolean>> {
        return this.http.post<ServiceResult<boolean>>(this.svrConfigUrl + 'validate', serverConfig);
    }
}
