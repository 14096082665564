import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {

    constructor() { }

    trimValues(object: any): any {
        Object.keys(object).map(function (key, index) {
            if (object[key] != null && typeof object[key] === 'string') {
                object[key] = object[key].trim();
            }
        });

        return object;
    }

    getObjectKeysLength(object: any): number {
        return Object.keys(object).length;
    }
}
