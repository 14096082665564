import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CanonicalFieldDescription } from '../../mapping/canonical-field-description';
import { ServiceResult } from '../service-result';

@Injectable()
export class CanonicalDomainService {
    private canonicalDomainUrl = 'api/canonicaldomain/';
    private failedResult: ServiceResult<string> = {
        isSuccessful: false,
        results: [],
        exceptions: [],
        message: '',
        resultType: 'string'
    };

    constructor(private http: HttpClient) { }

    getCanonicalClassTypes(): Observable<ServiceResult<string>> {
        return this.http.get<ServiceResult<string>>(this.canonicalDomainUrl + 'classes');
    }

    getCanonicalFields(type: string, objectDefinitionId: string = null): Observable<ServiceResult<CanonicalFieldDescription>> {
        let params = new HttpParams();

        if (objectDefinitionId) {
            params = params.append("objectDefinitionId", objectDefinitionId);
        }

        return this.http.get<ServiceResult<CanonicalFieldDescription>>(this.canonicalDomainUrl + 'fields/' + type + '/', { params: params });
    }

    getCanonicalLookupTypes(): Observable<ServiceResult<string>> {
        return this.http.get<ServiceResult<string>>(this.canonicalDomainUrl + 'lookups');
    }

    getFieldCompileStatuses(): Observable<ServiceResult<string>> {
        return this.http.get<ServiceResult<string>>(this.canonicalDomainUrl + 'fieldcompilestatuses/');
    }

    getEnumList(enumType: string): Promise<ServiceResult<any>> {
        return this.http.get<ServiceResult<any>>(this.canonicalDomainUrl + 'enumlist/' + enumType + '/')
                   .toPromise();
    }
}
