import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isNumeric'
})
export class IsNumericPipe implements PipeTransform {

    transform(value: any): boolean {
        return !isNaN(Number(value) - parseFloat(value));
    }
}
