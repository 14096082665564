import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AppService } from '@app/app.service';
import { CanonicalDomainService } from '@app/shared/canonical-domain/canonical-domain.service';
import { ErrorHandler } from '@app/shared/error-handler';
import { Globals } from '@app/globals';
import { GlobalsService } from '@app/globals.service';
import { RetsSystemService } from './shared/rets-system/rets-system.service';
import { ServerConfigurationService } from './server-configuration/server-configuration.service';
import { SharedModule } from '@app/shared';
import { SmartRetsEnvironmentService } from '@app/shared/smart-rets-environment/smart-rets-environment.service';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FlexLayoutModule,
        HttpClientModule,
        NgbModule.forRoot(),
        SharedModule
    ],
    providers: [
        AppService,
        CanonicalDomainService,
        ErrorHandler,
        Globals,
        GlobalsService,
        ServerConfigurationService,
        RetsSystemService,
        SmartRetsEnvironmentService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
