import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nameFromSubscriptionID'
})
export class NameFromSubscriptionIDPipe implements PipeTransform {
    transform(value: string): string {
        return value.split('-').pop().trim();
    }
}
