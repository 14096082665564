import { Component, Inject, OnInit } from '@angular/core';

import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
    selector: 'app-error-snackbar',
    templateUrl: './error-snackbar.component.html',
    styleUrls: ['./error-snackbar.component.scss']
})
export class ErrorSnackbarComponent implements OnInit {
    message: string;
    actionText: string;

    constructor(
        private snackbarRef: MatSnackBarRef<ErrorSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) private data
    ) {
        this.message = data.message;
        this.actionText = data.actionText;
    }

    ngOnInit() { }

    onActionClick() {
        this.snackbarRef.dismissWithAction();
    }
}
