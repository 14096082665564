import { Injectable } from '@angular/core';

import { SmartRetsEnvironment } from './shared/smart-rets-environment/smart-rets-environment';

@Injectable()
export class AppService {
    environment: SmartRetsEnvironment;

    constructor() { }

    setEnvironment(val: SmartRetsEnvironment): void {
        this.environment = val;
    }

    getEnvironment(): SmartRetsEnvironment {
        return this.environment;
    }
}
