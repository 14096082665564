import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";

import { of as observableOf,  Observable } from 'rxjs';

import { ErrorSnackbarComponent } from "@app/shared/error-snackbar/error-snackbar.component";

@Injectable()
export class ErrorHandler {
    constructor(public snackbar: MatSnackBar) { }

    public handleError(err: any, showSnackbar: boolean = false): Observable<any> {
        let returnObject: any = err,
            errorMessage: string;
        if (typeof err == 'string' || err instanceof String) {
            errorMessage = err.toString();
        }
        if (err instanceof HttpErrorResponse) {
            errorMessage = err.message;

            // Check if error is of Service Result type
            if (err.error && err.error.hasOwnProperty('isSuccessful') && err.error.hasOwnProperty('message')) {
                errorMessage = err.error.message;
                returnObject = err.error;
            }
        }
        else if(err.hasOwnProperty('isSuccessful') && err.hasOwnProperty('message')) {
            errorMessage = err.message;
        }

        if (showSnackbar) {
            this.snackbar.openFromComponent(ErrorSnackbarComponent,
                {
                    data: {
                        message: errorMessage,
                        actionText: 'dismiss'
                    },
                    panelClass: 'error-snackbar',
                    verticalPosition: 'top'
                });
        }

        return observableOf(returnObject);
    }
}
