export class ServiceResult<T> {
    isSuccessful: boolean;
    results: T[];
    exceptions: string[];
    message: string;
    resultType: string;
    totalCount?: number;
    pageIndex?: number;
    pageSize?: number;

    constructor(isSuccessful: boolean = false, message: string = '', results: T[] = []) {
        this.isSuccessful = isSuccessful;
        this.message = message;
        this.results = results;
    }
}
