import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { first, catchError, takeUntil } from 'rxjs/operators';

import { AppService } from './app.service';
import { environment } from '../environments/environment';
import { ErrorHandler } from '@app/shared/error-handler';
import { GlobalsService } from './globals.service';
import { ServiceResult } from '@app/shared/service-result';
import { SmartRetsEnvironment } from '@app/shared/smart-rets-environment/smart-rets-environment';
import { SmartRetsEnvironmentService } from '@app/shared/smart-rets-environment/smart-rets-environment.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    @HostBinding('class') componentCssClass;

    mobileQuery: MediaQueryList;
    year = new Date().getFullYear();
    logo = require('../assets/CRMLS_white.png');
    navigation = [
        { link: 'sources', label: 'Sources' },
        { link: 'mapping', label: 'Mapping' },
        { link: 'logs', label: 'Subscription Logs' },
        { link: 'updates', label: 'Updates' },
        { link: 'operations', label: 'Operations' },
        { link: 'admin', label: 'Admin' }
    ];
    navigationSideMenu = [
        ...this.navigation,
    ];
    isAuthenticated;

    appReady: boolean = false;
    environment: SmartRetsEnvironment;
    classSelectable: boolean = true;
    version: string = environment.VERSION;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public errorHandler: ErrorHandler,
        private appSvc: AppService,
        private envSvc: SmartRetsEnvironmentService,
        private globalsSvc: GlobalsService
    ) { }

    ngOnInit(): void {
        this.appReady = false;

        this.globalsSvc.populateEnums();

        this.envSvc.getCurrentEnvironment()
            .pipe(
                catchError(error => this.errorHandler.handleError(error, true)),
                first()
            )
            .subscribe((result: ServiceResult<SmartRetsEnvironment>) => {
                if(result.isSuccessful) {
                    this.appSvc.setEnvironment(result.results[0]);
                }
                else {
                    this.appSvc.setEnvironment(null);
                }

                this.environment = this.appSvc.getEnvironment();
                this.appReady = true;
            });

        
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
