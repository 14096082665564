import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subject, Subscription ,  forkJoin } from 'rxjs';
import { catchError, finalize, first, take, takeUntil, tap, map } from 'rxjs/operators';

import { ErrorHandler } from '@app/shared/error-handler';
import { ServerConfiguration } from '@app/server-configuration/server-configuration';
import { ServerConfigurationService } from '../../server-configuration/server-configuration.service';
import { ServiceResult } from '@app/shared/service-result';
import { RetsClass, RetsResource } from '../rets-system/rets-source';
import { RetsLookup } from '@app/shared/rets-system/rets-lookup';
import { RetsSystemService } from '@app/shared/rets-system/rets-system.service';

@Component({
    selector: 'app-source-resource-class-selection',
    templateUrl: './source-resource-class-selection.component.html',
    styleUrls: ['./source-resource-class-selection.component.scss']
})
export class SourceResourceClassSelectionComponent implements OnDestroy, OnInit {
    @Input() selection: SourceResourceClassSelect;
    @Output() selected: EventEmitter<SourceResourceClassSelect> = new EventEmitter();

    classes: RetsClass[] = [];
    classRequest: Subscription = new Subscription();
    classTypes: any[];
    filteredClasses: string[];
    isLoadingClasses: boolean;
    isLoadingResources: boolean;
    isLoadingSources: boolean;
    lookups: RetsLookup[] = [];
    resourceRequest: Subscription = new Subscription();
    resources: RetsResource[] = [];
    selectionForm: FormGroup;
    sources: ServerConfiguration[];

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public errorHandler: ErrorHandler,
        private formBuilder: FormBuilder,
        private serverConfigSvc: ServerConfigurationService,
        private retsSysSvc: RetsSystemService
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // Default null
        this.selection.class = this.selection.class == null ? '': this.selection.class;

        // Build selection form
        this.selectionForm = this.formBuilder.group({
            source: this.selection.source,
            resource: [{ value: this.selection.resource, disabled: !this.selection.source }],
            type: [{ value: this.selection.classType, disabled: !this.selection.resource }],
            class: [{ value: this.selection.class, disabled: (!this.selection.resource && !this.selection.classType) }]
        });

        // Get list of class types
        this.classTypes = this.buildEnumArray(ClassType);

        // Get list of sources
        this.serverConfigSvc.getServerConfigurations()
            .pipe(
                tap(() => this.isLoadingSources = true),
                finalize(() => this.isLoadingSources = false),
                catchError(error => this.errorHandler.handleError(error, true)),
                take(1),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((result: ServiceResult<ServerConfiguration>) => {
                if(result && result.isSuccessful) {
                    this.sources = result.results;
                }
                else {
                    this.sources = [];
                }

                // Get list of resources if source is selected
                if(this.selection.source) {
                    this.loadResources();
                }

                // Get classes & lookups if resource is selected
                if(this.selection.resource){
                    this.loadClasses();
                }
            });

        // Start filtering classes/lookups autocomplete
        this.selectionForm.get('class')
            .valueChanges
            .pipe(
                map(val => this.filter(val)),
                catchError((error, caught) => this.errorHandler.handleError(error, true)),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((filteredList: string[]) => {
                this.filteredClasses = filteredList;
            });

        this.onChanges();
    }

    buildEnumArray(enumme): Object[] {
        return Object.keys(enumme)
            .filter(key => typeof enumme[key] === 'number')
            .map(key => (
                    {
                        id: enumme[key],
                        name: key
                    }
                )
            );
    }

    emitSelected(): void {
        this.selected.emit(this.selection);
    }

    filter(val: string): string[] {
        switch(this.selection.classType) {
            case ClassType.Lookup:
                return this.lookups
                    .filter(option => option.name.toLowerCase().includes(val.toLowerCase()))
                    .map(option => option.name);
            default:
                return this.classes
                    .filter(option => option.name.toLowerCase().includes(val.toLowerCase()))
                    .map(option => option.name);
        }
    }

    getEnumName(key: number): string {
        return ClassType[key];
    }

    loadClasses(): void {
        this.isLoadingClasses = false;
        this.selectionForm.controls['class'].disable();
        this.filteredClasses = [];

        this.classRequest = forkJoin(
                this.retsSysSvc.getClasses(this.selection.source, this.selection.resource)
                .pipe(
                    catchError((error, caught) => this.errorHandler.handleError(error, true)),
                    take(1)
                ),
                this.retsSysSvc.getLookups(this.selection.source, this.selection.resource)
                .pipe(
                    catchError((error, caught) => this.errorHandler.handleError(error, true)),
                    take(1)
                )
            )
            .pipe(
                catchError((error, caught) => this.errorHandler.handleError(error, true)),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(([classesResult, lookupsResult]) => {
                if(classesResult && classesResult.isSuccessful) {
                    this.classes = classesResult.results;

                    //if(!this.selection.class && this.selection.classType == ClassType.Class && this.classes.length == 1) {
                    //    this.onClassSelection({
                    //        option: {
                    //            value: this.classes[0].name
                    //        }
                    //    });
                    //}
                }
                else {
                    this.classes = [];
                }
                if(lookupsResult && lookupsResult.isSuccessful) {
                    this.lookups = lookupsResult.results;

                    //if(!this.selection.class && this.selection.classType == ClassType.Lookup && this.lookups.length == 1) {
                    //    this.onClassSelection({
                    //        option: {
                    //            value: this.lookups[0].name
                    //        }
                    //    });
                    //}
                }
                else {
                    this.lookups = [];
                }

                // Reset autocomplete list once classes & lookups have loaded
                let classField = this.selection.class ? this.selection.class : '';

                this.filteredClasses = this.filter(classField);

                this.isLoadingClasses = false;
                this.selectionForm.controls['class'].enable();
            });
    }

    loadResources(): void {
        this.isLoadingResources = true;

        this.resourceRequest = this.retsSysSvc.getResources(this.selection.source)
            .pipe(
                catchError((error, caught) => this.errorHandler.handleError(error, true)),
                first(),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((result: ServiceResult<RetsResource>) => {
                if(result && result.isSuccessful) {
                    if (result.results.length === 2 && result.results[1].name === 'NonExistent') {
                        result.results.pop();
                    }
                    this.resources = result.results;

                    //if(this.selection.resource && this.resources.length === 1) {
                    //    this.selectionForm.controls.resource.setValue(this.resources[0].name);
                    //}
                }
                else {
                    this.resources = [];
                }

                this.isLoadingResources = false;
            });
    }

    onChanges(): void {
        this.selectionForm.get('source')
            .valueChanges
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(val => {
                // Cancel previous resources request before new request
                this.resourceRequest.unsubscribe();

                let prevSourceValue = this.selection.source;
                let prevResourceValue = this.selection.resource;

                // Set new source value
                this.selection = {
                    source: val,
                    resource: '',
                    classType: this.selection.classType,
                    class: ''
                };

                // Only loadResources if source is not going from/to null
                if(prevSourceValue && val && !prevResourceValue) {
                    this.loadResources();
                }

                this.emitSelected();
            });

        this.selectionForm.get('resource')
            .valueChanges
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(val => {
                // Cancel previous class/lookup request before new request
                this.classRequest.unsubscribe();

                let prevResourceValue = this.selection.resource;
                let previousClassValue = this.selection.class;

                this.selection = {
                    source: this.selection.source,
                    resource: val,
                    classType: this.selection.classType,
                    class: ''
                };

                // Only loadClasses if resource value is not going from/to null
                if (prevResourceValue && val && !previousClassValue) {
                    this.loadClasses();
                }

                this.emitSelected();
            });
    }

    onClassClear(): void {
        this.selection.class = '';
        this.selectionForm.controls['class'].setValue('');
        this.emitSelected();
    }

    onClassSelection(event): void {
        switch(this.selection.classType) {
            case ClassType.Lookup:
                this.selection.class = event.option.value;
                break;
            default:
                this.selection.class = event.option.value;
                break;
        };

        this.emitSelected();
    }

    onTypeChange(event): void {
        this.selection.classType = event.value;

        // Reset class value
        this.selectionForm.controls['class'].setValue('');

        // Reset autocomplete list
        this.filteredClasses = this.filter('');

        if (this.selection.class) {
            if(this.selection.classType == ClassType.Class && this.classes.length == 1) {
                this.onClassSelection({
                    option: {
                        value: this.classes[0]
                    }
                });
            }
            else if(this.selection.classType == ClassType.Lookup && this.lookups.length == 1) {
                this.onClassSelection({
                    option: {
                        value: this.lookups[0].name
                    }
                });
            }
            else {
                this.emitSelected();
            }
        }
        else {
            this.emitSelected();
        }
    }
}

export interface SourceResourceClassSelect {
    source: string,
    resource: string,
    classType: ClassType,
    class: string
}

export enum ClassType {
    Class,
    Lookup
}
