import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ErrorHandler } from './shared/error-handler';
import { RetsSource } from './shared/rets-system/rets-source';
import { ServerConfiguration } from './server-configuration/server-configuration';

@Injectable()
export class Globals {
    importCountErrorThreshold = 1500;
    importCountWarningThreshold = 1000;
    publishFailedErrorThreshold = 25;

    auditSubscriptionStatuses: Record<number, string> = {};
    auditSubscriptionTypes: Record<number, string> = {};
    augmentationScopes: Record<number, string> = {};
    transformStatuses: Record<number, string> = {};
    updateDestinations: Record<number, string> = {};
    updateStrategies: Record<number, string> = {};

    serverConfigurations: ServerConfiguration[] = [];
    sources: RetsSource[];

    readonly defaultAuditSubscriptionQuery = '({timestamp}={querystart}%2B)';
    readonly SUBSCRIPTION_TYPES = {
        audit: 'audit',
        capture: 'capture'
    };

    constructor(
        public errorHandler: ErrorHandler
    ) { }

    resetGlobals(): boolean {
        this.serverConfigurations = [];
        this.sources = [];
        this.transformStatuses = {};
        this.updateDestinations = {};
        this.updateStrategies = {};
        this.augmentationScopes = {};
        this.auditSubscriptionStatuses = {};
        this.auditSubscriptionTypes = {};

        return true;
    }

    resetSources(): boolean {
        this.sources = [];

        return true;
    }
}
