import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trim'
})
export class TrimPipe implements PipeTransform {
    transform(value: string, type: TrimType = TrimType.All): any {
        if(!value) {
            return '';
        }

        switch(type) {
            case TrimType.Start:
            {
                return value.trimLeft();
            }
            case TrimType.End:
            {
                return value.trimRight();
            }
            default:
            {
                return value.trim();
            }
        }
    }
}

export enum TrimType {
    All,
    Start,
    End
}
