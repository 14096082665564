import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: 'operations', pathMatch: 'full' },
    {
        path: 'sources',
        loadChildren: '@app/server-configuration/server-configuration.module#ServerConfigurationModule'
    },
    {
        path: 'subscriptions',
        loadChildren: '@app/subscription/subscription.module#SubscriptionModule'
    },
    {
        path: 'mapping',
        loadChildren: '@app/mapping/mapping.module#MappingModule'
    },
    {
        path: 'logs',
        loadChildren: '@app/logs/logs.module#LogsModule'
    },
    {
        path: 'updates',
        loadChildren: '@app/transforms/transforms.module#TransformsModule'
    },
    {
        path: 'operations',
        loadChildren: '@app/operations/operations.module#OperationsModule'
    },
    {
        path: 'admin',
        loadChildren: '@app/admin/admin.module#AdminModule'
    },
    { path: '**', redirectTo: 'operations' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
