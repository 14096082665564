import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TextMaskModule } from 'angular2-text-mask';

import { CanonicalDomainService } from './canonical-domain/canonical-domain.service';
import { CdkDetailRowDirective } from './cdk-detail-row.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DatetimeToUTCMomentPipe } from './datetime-to-utcmoment.pipe';
import { ErrorSnackbarComponent } from './error-snackbar/error-snackbar.component';
import { IsNumericPipe } from './is-numeric.pipe';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { LocalTimezoneDisplayPipe } from './local-timezone-display.pipe';
import { NameFromSubscriptionIDPipe } from '@app/shared/name-from-subscription-id.pipe';
import { RetsSystemService } from '@app/shared/rets-system/rets-system.service';
import { SharedService } from './shared.service';
import { SourceResourceClassSelectionComponent } from './source-resource-class-selection/source-resource-class-selection.component';
import { TrimPipe } from './trim-pipe.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgxDatatableModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        TextMaskModule
    ],
    declarations: [
        CdkDetailRowDirective,
        ConfirmationDialogComponent,
        DatetimeToUTCMomentPipe,
        ErrorSnackbarComponent,
        IsNumericPipe,
        LoadingDialogComponent,
        LoadingPageComponent,
        LocalTimezoneDisplayPipe,
        NameFromSubscriptionIDPipe,
        SourceResourceClassSelectionComponent,
        TrimPipe,
    ],
    exports: [
        CdkDetailRowDirective,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingPageComponent,
        NgbModule,
        NgxDatatableModule,
        LocalTimezoneDisplayPipe,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        SourceResourceClassSelectionComponent,
        TextMaskModule,
        TrimPipe
    ],
    providers: [
        CanonicalDomainService,
        DatetimeToUTCMomentPipe,
        IsNumericPipe,
        LocalTimezoneDisplayPipe,
        RetsSystemService,
        SharedService,
        TrimPipe
    ],
    entryComponents: [
        ConfirmationDialogComponent,
        ErrorSnackbarComponent,
        LoadingDialogComponent
    ]
})
export class SharedModule { }
