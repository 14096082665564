import { Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'localTimezoneDisplay'
})
export class LocalTimezoneDisplayPipe extends DatePipe {

    transform(value: any, pattern: string = 'mediumDate'): string|null {
        let result = super.transform(value, pattern);
        result += " " + this.map[Intl.DateTimeFormat().resolvedOptions().timeZone];
        return result;
    }

    map = {
        "America/Los_Angeles": "PT"
    }
}
