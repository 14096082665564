
import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RetsField } from '@app/shared/rets-system/rets-field';
import { RetsLookup } from '@app/shared/rets-system/rets-lookup';
import { ServiceResult } from '@app/shared/service-result';
import { RetsResource, RetsSource } from './rets-source';

@Injectable()
export class RetsSystemService {
    private failedStringResult: ServiceResult<string> = new ServiceResult<string>();
    private failedRetsFieldResult: ServiceResult<RetsField> = new ServiceResult<RetsField>();
    private failedRetsLookupResult: ServiceResult<RetsLookup> = new ServiceResult<RetsLookup>();
    private retsSysUrl = 'api/retssystem';

    constructor(private http: HttpClient) { }

    getClasses(sourceId: string, retsResource: string): Observable<ServiceResult<string>> {
        if (sourceId && retsResource) {
            return this.http.get<ServiceResult<string>>(this.retsSysUrl
                + '/sources/'
                + sourceId
                + '/resources/'
                + retsResource
                + '/classes');
        }

        // If no source or resource are provided, return a failed result
        this.failedStringResult.isSuccessful = false;
        this.failedStringResult.message = 'Source and Resource are required.';

        return observableOf(this.failedStringResult);
    }

    getField(sourceId: string, retsResource: string, retsClass: string, fieldName: string): Observable<ServiceResult<RetsField>> {
        if(sourceId && retsResource && retsClass && fieldName) {
            return this.http.get<ServiceResult<RetsField>>(this.retsSysUrl
                + '/sources/'
                + sourceId
                + '/resources/'
                + retsResource
                + '/classes/'
                + retsClass
                + '/fields/'
                + fieldName);
        }

        // If not all inputs are provided, return a failed result
        this.failedRetsFieldResult.isSuccessful = false;
        this.failedRetsFieldResult.message = 'Source, Resource, Class, and the Field Name are required.';

        return observableOf(this.failedRetsFieldResult);
    }

    getFields(sourceId: string, retsResource: string, retsClass: string): Observable<ServiceResult<RetsField>> {
        if (sourceId && retsResource && retsClass) {
            return this.http.get<ServiceResult<RetsField>>(this.retsSysUrl
                + '/sources/'
                + sourceId
                + '/resources/'
                + retsResource
                + '/classes/'
                + retsClass
                + '/fields');
        }

        // If not all inputs are provided, return a failed result
        this.failedRetsFieldResult.isSuccessful = false;
        this.failedRetsFieldResult.message = 'Source, Resource, and Class are required.';

        return observableOf(this.failedRetsFieldResult);
    }

    getLookups(sourceId: string, retsResource: string): Observable<ServiceResult<RetsLookup>> {
        if (sourceId && retsResource) {
            return this.http.get<ServiceResult<RetsLookup>>(this.retsSysUrl
                + '/sources/'
                + sourceId
                + '/resources/'
                + retsResource
                + '/lookups');
        }

        // If no source or resource are provided, return a failed result
        this.failedRetsLookupResult.isSuccessful = false;
        this.failedRetsLookupResult.message = 'Source and Resource are required.';

        return observableOf(this.failedRetsLookupResult);
    }

    getResources(sourceID: string): Observable<ServiceResult<string>> {
        if (sourceID) {
            return this.http.get<ServiceResult<string>>(this.retsSysUrl + '/sources/'
                + sourceID
                + '/resources');
        }

        // If no source is provided, return a failed result
        this.failedStringResult.isSuccessful = false;
        this.failedStringResult.message = 'Source is required.';

        return observableOf(this.failedStringResult);
    }

    async getResourcesSynchronous(sourceId: string): Promise<ServiceResult<RetsResource>> {
        if (sourceId) {
            return await this.http
                .get<ServiceResult<RetsResource>>(this.retsSysUrl + '/sources/' + sourceId + '/resources')
                .toPromise();
        }
        else {
            var failedRetsResourceResult = new ServiceResult<RetsResource>(false, 'Source is required.');

            return observableOf(failedRetsResourceResult).toPromise();
        }

    }

    getSources(): Observable<ServiceResult<RetsSource>> {
        return this.http.get<ServiceResult<RetsSource>>(this.retsSysUrl + '/sources');
    }

    async getSourcesSynchronous(): Promise<ServiceResult<RetsSource>> {
        return await this.http.get<ServiceResult<RetsSource>>(this.retsSysUrl + '/sources').toPromise();
    }
}
