import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'datetimeToUtcmoment'
})
export class DatetimeToUTCMomentPipe implements PipeTransform {
    transform(value: any[]): any {
        for(let obj of value) {
            Object.keys(obj).map(function(key, index) {
                if(obj[key] != null && moment(obj[key].toString().substr(0, 19), 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    obj[key] = moment(obj[key]).utc(true);
                }
            });
        }

        return value;
    }
}
